import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import RedirectHandler from "./RedirectHandler";
import PrivateRoute from "./ProtectedRoute";
import { InteractionStatus } from "@azure/msal-browser";
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));
const Ongoing = lazy(() => import("../../DemoPages/Ongoing"));
const RetestOngoing = lazy(() => import("../../DemoPages/Retest-Ongoing"));
const RetestPending = lazy(() => import("../../DemoPages/Retest-Pending"));
const ReadyForCompletion = lazy(() =>
  import("../../DemoPages/Ready-For-Completion")
);
const RetestTimedOut = lazy(() => import("../../DemoPages/Retest-TimedOut"));
const TBD = lazy(() => import("../../DemoPages/TBD-Projects"));
const WeeklyProjects = lazy(() =>
  import("../../DemoPages/WeeklyTeamsProjects")
);
const Search = lazy(() => import("../../DemoPages/Search"));
const Paused = lazy(() => import("../../DemoPages/Paused"));
const Info = lazy(() => import("../../DemoPages/Documentation"));
const Admin = lazy(() => import("../../DemoPages/Admin-Only"));
const Forms = lazy(() => import("../../DemoPages/Forms"));
const Login = lazy(() => import("../../DemoPages/Login"));

const isTokenExpired = () => {
  const expiresAt = localStorage.getItem("msal.expiresAt");
  if (!expiresAt) return true; // If there's no expiry time, treat the token as expired
  return Date.now() >= parseInt(expiresAt, 10);
};

const AppMain = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const isTokenValid = !isTokenExpired();
  if (
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect
  ) {
    return (
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="line-scale-party" />
          </div>
          <h6 className="mt-3">Please wait while we Refresh the Page</h6>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3">Please wait while we load Dashboard Page</h6>
            </div>
          </div>
        }
      >
        <Route
          exact
          path="/"
          render={() =>
            isAuthenticated && isTokenValid ? (
              <Redirect to="/litmus/dashboard" />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </Suspense>
      {/* login & redirect */}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3">Please wait while we load Login Page</h6>
            </div>
          </div>
        }
      >
        <Route path="/login" component={Login} />
        
        <Route path="/redirect" component={RedirectHandler} />
      </Suspense>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Please wait while we load the Dashboard
                <small>Fun Fact: A little patience goes a long way!</small>
              </h6>
            </div>
          </div>
        }
      >
        {/* <Route path="/litmus" component={Dashboards} /> */}
        <PrivateRoute path="/litmus" component={Dashboards} />
      </Suspense>
      {/* Filter Projects*/}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Projects
                <small>Have a little patience !</small>
              </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/ongoing" component={Ongoing} />
        <PrivateRoute path="/retest-ongoing" component={RetestOngoing} />
        <PrivateRoute path="/retest-pending" component={RetestPending} />
        <PrivateRoute path="/paused" component={Paused} />
        <PrivateRoute
          path="/restest-report-submitted"
          component={ReadyForCompletion}
        />
        <PrivateRoute path="/retest-timed-out" component={RetestTimedOut} />
        <PrivateRoute
          path="/weekly-teams-projects"
          component={WeeklyProjects}
        />
        <PrivateRoute path="/tbd-projects" component={TBD} />
      </Suspense>
      {/* Forms */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">
                Please wait while we load the Form
              </h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/create" component={Forms} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Please wait while we load Search</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/search" component={Search} />
      </Suspense>

      {/* Docs */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse" />
              </div>
              <h6 className="mt-3">Please wait while we load all the Docs</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/internal" component={Info} />
      </Suspense>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse" />
              </div>
              <h6 className="mt-3">Please wait while we load Admin Panel</h6>
            </div>
          </div>
        }
      >
        <PrivateRoute path="/admin" component={Admin} />
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
